<template>
    <!--max-width="1000px" class="pa-4 grey darken-4 mx-auto"-->
    <div class="flex-grow-1">
        <v-scale-transition>
        <!--<v-container class="pa-4 grey darken-4" v-show="(authKeyId || showForm) && isPartner">-->
            <v-row v-show="(authKeyId || showForm) && isPartner" class="justify-center ma-0">
                <v-col cols="12" md="10" lg="8" class="pa-0">
                    <v-toolbar dense dark color="blue-grey darken-4" elevation="0">
                        <v-toolbar-title>Ключ авторизации</v-toolbar-title>
                    </v-toolbar>

                    <v-sheet elevation="12" class="blue-grey darken-3 pa-4">

                        <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        >
                            <!--no-gutters-->
                                            <!-- :rules="[v => !!v || 'Обязательное поле']" -->
                            <v-row class="mb-n4">
                                <v-col cols="12" sm="6" class="mb-n4">
                                    <v-text-field
                                            v-model="authkey"
                                            label="Authkey"
                                            required
                                            outlined
                                            hint="Ключ авторизации"
                                            readonly
                                            persistent-hint
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-4">
                                    <v-select
                                            v-model="status"
                                            :items="statusItems"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Статус"
                                            required
                                            hide-details
                                            outlined
                                            return-object
                                            :readonly="!authKeyId"
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="6" class="mb-3">
                                    <v-text-field
                                            v-model="createTime"
                                            label="Создан UTC"
                                            outlined
                                            readonly
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-3">
                                    <v-text-field
                                            v-model="updateTime"
                                            label="Изменен UTC"
                                            outlined
                                            readonly
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-form>

                        <v-card-actions class="pa-0">
                            <v-spacer></v-spacer>
                            <v-btn color="success" @click="submit" :disabled="!valid">{{!authKeyId ? 'Добавить' : 'Изменить'}}</v-btn>
                            <!--<v-btn color="primary" :disabled="!valid" type="submit">Login</v-btn>-->
                        </v-card-actions>
                    </v-sheet>
                </v-col>
            </v-row>
        <!--</v-container>-->
        </v-scale-transition>
        <v-fab-transition>
            <!--large-->
            <v-btn
                    v-show="!authKeyId && !showForm && initialized && isPartner"
                    @click="showForm = !showForm"
                    dark
                    elevation="12"
                    fixed
                    fab
                    bottom
                    right
                    class="mb-16 mr-8 pink">
                <v-icon>add</v-icon>
            </v-btn>
        </v-fab-transition>
    </div>
</template>

<script>
  export default {
    name: "AuthKeyForm",
    props: ['holder'],

    watch:{
      holder:
        function(v){
          //console.log(v);
          this.initialized = false;
          this.init();
        },
    },

    computed: {
      isPartner: function(){
        //console.log(this.ref);
        let re = new RegExp(/^holder::partner::(\w|\.|-)+$/gi);
        return re.test(this.ref);
      },
    },
    
    data: () => ({
      valid: false,
      showForm: false,
      initialized: false,
      //disabled: true,
      holderId: null,
      authKeyId: null,
      ref: '',
      status: {},
      statusItems: [
        {text: 'Активен', value:'active'},
        {text: 'Отключен', value:'disabled'},
      ],
      authkey: '',
      createTime: '',
      updateTime: '',
    }),

    mounted(){
      this.init();
    },

    methods: {
/*
      onFileChange(){
        //console.log(this.logoFile);
        if (this.logoFile){
          this.previewUrl = URL.createObjectURL(this.logoFile);
        }
        else
          this.previewUrl = null;
      },
*/

/*
      onUpload() {
        console.log(this.logoFile);
      },
*/

      init(){
        //console.log(this.refValidate('holder::multinet::100046'));
        if (!this.holder){
          this.holderId = null;
          this.authKeyId = null;
          this.initialized = true;
          //this.$emit('update-title', 'Пользователь: Новый');
          //this.$emit('update-title', {title: '<Новый>'});
        }
        else {
          this.holderId = this.holder;
          this.status = this.statusItems[0];
          //потом сделать наоборот, если цифра то гет данные иначе новый
          this.get(this.holderId);
        }
        //console.log(this.holder);
      },

      get(id){
        this.$store.dispatch("setLoading", true);
        try{
          this.$store.dispatch("getHolderAuthKey", id)
            .then((response) => {
                //console.log(response);//.data.holderMarket.props.category);
                this.ref = response.data.ref;
                if (response.data.authKeyId){
    //            if (this.authKeyId){
                  this.authKeyId = response.data.authKey.authKeyId;
                  this.status = this.statusItems.filter( v => (v.value == response.data.authKey.status))[0];
                  this.authkey = response.data.authKey.key;
                  this.createTime = response.data.authKey.createTime;
                  this.updateTime = response.data.authKey.updateTime;
                  //надо что-то сделать с logoFile, или инит его через logoRoute или хотя бы показать превью, и сбросить модель
                  this.$nextTick(() => this.resetValidation());
                  //this.$emit('update-holder', {title: this.holderName, holderType: this.holderType.value});
                  //this.$store.dispatch("routerPushWrap", '/holder/' + response.data.holderId);
                }
            })
            .then(null,(error) => {
                //console.log(error.message.toString());
                this.$store.dispatch("showMessage", {text: 'Ошибка получения authkey: '+ error.message, error: true});
            })
            .finally(() => {
                this.$store.dispatch("setLoading", false);
                this.initialized = true;
                //console.log(this.isPartner, this.ref);
            });
        }
        catch(e){
          this.$store.dispatch("showMessage", {text: e.message, error: true});
          this.$store.dispatch("setLoading", false);
        }
      },

      submit(){
        if (this.validate()){
          //console.log(a);
          this.$store.dispatch("setLoading", true);

          try{
            const reqParams = {
              holderId: this.holderId,
              authKeyId: this.authKeyId,
              status: this.status.value,
            };

            let reqMethod = '';
            if (!this.authKeyId){
              reqMethod = 'postHolderAuthKey';
            }
            else{
              reqMethod = 'putAuthKey';
            }

            this.$store.dispatch(reqMethod, reqParams)
              .then((response) => {
                //console.log(response);
                this.initialized = false;
                //this.holderId = response.data.holderId;
                this.get(this.holderId);
/*
                if (method == 'postHolderCatalog'){
                  //this.$store.dispatch("routerPushWrap", {name: "Catalog", params: {holderId: '' + response.data.holderId}});//'/holder/' + response.data.holderId);
                  //this.$store.dispatch("routerPushWrap", {path:'/holder/' + response.data.holderId});
                  this.$store.dispatch("routerPushWrap", '/holder/' + response.data.holderId + '/catalog');
                  //this.partners = response.data.data.map((i) => ({id: i.id, name: i.shortName, holderRouteId: i.holderRouteId}));
                }
                else if (method == 'putHolderCatalog'){
                  //this.get(this.holderId);
                }
*/
              })
              .then(null,(error) => {
                console.log(error);
                //this.$store.dispatch("showMessage", {text: 'Ошибка получения данных', error: true});
              })
              .finally(() => {
                this.$store.dispatch("setLoading", false);
              });
          }
          catch(e){
            this.$store.dispatch("showMessage", {text: e.message, error: true});
            this.$store.dispatch("setLoading", false);
          }
        }
      },

      validate(){
        //console.log();
        return this.$refs.form.validate();
      },

      reset(){
        this.$refs.form.reset();
      },

      resetValidation(){
        if (this.$refs['form']){
          this.$refs.form.resetValidation();
        }
      },
    },
  };
</script>

<style scoped>
</style>